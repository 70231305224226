<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevators/page">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('elevator.elevatorType')">
          <el-select v-model="search.categoryCode" :style="{ width: $l('250px', '150px') }"
                     :placeholder="$t('elevator.elevatorType')" clearable>
            <el-option v-for="item in categoryList" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.onlineStatus')">
          <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')" clearable>
            <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.joinStatus')">
          <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')" clearable>
            <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('elevator.gateWayType')">
          <el-select v-model="search.gateWayType" :placeholder="$t('elevator.gateWayType')" clearable>
            <el-option v-for="item in gateWayTypeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')">
          <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')" clearable>
            <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
              :value="item.integrationKey"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('elevator.no')" align="center">
        <template slot-scope="scope">
          <router-link v-if="scope.row.id" :to="'/elevator/' + scope.row.id" target="_blank">
            {{ scope.row.no }}
          </router-link>
          <router-link v-else :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip
        :label="$t('dayWeekMonthManger.elevatorName')"></el-table-column>
      <el-table-column prop="regNo" show-overflow-tooltip :label="$t('elevator.registerCode')"></el-table-column>
      <el-table-column prop="factoryNo" show-overflow-tooltip :label="$t('elevator.exFactoryNo')"></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="100" align="center">
        <template v-slot="scope">
            <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
              <span>{{
                  item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" :
                    ",")
                }}</span>
            </span>
        </template>
      </el-table-column>
      <el-table-column v-if="whichPage === 0" prop="inNetState" :label="$t('elevator.joinStatus')" width="80"
                       align="center">
        <template v-slot="scope">
          <el-tag v-if="scope.row.inNetState" type="success">{{$t("elevator.joined")}}</el-tag>
          <el-tag v-else type="info">{{$t("elevator.notJoined")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-else prop="onlineStatus" :label="$t('monitor.onlineStatus')" width="80" align="center">
        <template v-slot="scope">
          <el-tag v-if="scope.row.onlineStatus === 1" type="success">{{$t("monitor.online")}}</el-tag>
          <el-tag v-else type="info">{{$t("monitor.offline")}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="100" align="center">
        <template v-slot="scope">
          <el-button type="text">
            <router-link
              :to="{ name: 'singleElevatorReport', params: { id: scope.row.id } }"
              target="_blank">{{ $t("contract.check") }}</router-link>
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
  </div>
</template>
<script>
export default {
  props: {
    whichPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      search: {
        inNetState:"true"
      }, // 搜索条件
      productTypeOptions: [],  // 梯种
      categoryList: [],
      integrationList: [], // 对接平台
      inNetStateOptions: [
        { value: "false", label: this.$t("elevator.notJoined") },
        { value: "true", label: this.$t("elevator.joined") },
      ],
      activatedPartsStatus: [
        { value: "false", label: this.$t("elevator.notActivated") },
        { value: "true", label: this.$t("elevator.activated") },
      ],
      onlineStatusOptions: [
        { value: 0, label: this.$t("device.offline") },
        { value: 1, label: this.$t("device.online") },
      ],
      gateWayTypeOptions: [
        { value: 1, label: this.$l("elevator.gateWayType1") },
        { value: 3, label: this.$l("elevator.gateWayType3") },
      ],
    };
  },
  mounted() {
    this.getList(1);
    this.getProductTypeOptions();
    this.getIntegrationList();
  },
  methods: {

    // 获取列表
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },

    // 获取梯种
    getProductTypeOptions() {
      this.$api.getList("elevatorProductTypes").then(response => {
        for (let productType of response.data) {
          let item2 = "";
          if (this.$i18n.isCn) {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeName};
          } else {
            item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeNameEn};
          }
          this.categoryList.push(item2);
        }
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },

    // 获取对接平台
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
  },
};
</script>
